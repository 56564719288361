import React from 'react';
import { Container,Box } from 'theme-ui';
import SEO from '../components/seo';
import SecondaryLayout from '../components/layout/secondary/secondary';
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import get from 'lodash/get';
import styles from '../components/home-banner/home-banner.style';

const aboutPageStaticQuery = graphql`
	query {
		prismic {
			allAboutchat2orders {
				edges {
					node {
					  intro,
					  intro_banner,
					  seller_info,
					  seller_info_banner {
						seller_info_image,
						seller_info_image_title
					  },
					  seller_info_addl,
					  platform_info,
					  client_testimonials_header,
					  client_testimonials {
						client_testimonials_image,
						client_testimonials_image_title
					  },
					  company_info,
					  call_us_banner
					}
				}
			}
		}
	}
`;

const aboutPage: React.FC<{}> = () => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${aboutPageStaticQuery}`}
		render={(data) => {
			const node = get(data, 'prismic.allAboutchat2orders.edges[0].node');
			const intro = node.intro;
			const intro_banner = node.intro_banner.url;
			const seller_info = node.seller_info;
			const seller_info_addl = node.seller_info_addl;
			const platform_info = node.platform_info;
			const client_testimonials_header = node.client_testimonials_header;
			const company_info = node.company_info;
			const call_us_banner = node.call_us_banner.url;
			

			return (
				<SecondaryLayout>
					<SEO title="Chat2Order About Us" />
					
					

					<Box
						as="section"
						sx={{
							backgroundImage: `url(${intro_banner && intro_banner ? intro_banner : bannerImg})`,
							...styles.banner,
						}}
						className="home-banner"
					>		
					</Box>	

					<Container py="40px">
					{RichText.render(intro)}
					</Container>

					<Container py="40px">
					{RichText.render(platform_info)}
					</Container>

					<Container py="40px">
					{RichText.render(seller_info)}
					</Container>

					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							"& > div": {
							width: ["100%", "50%", "25%"],
							p: 2,
							position: "relative",
      						overflow: "hidden",
							textAlign: "center"
							}
						}}
						>
						{node.seller_info_banner.map((banner) => (
							<div key={banner.seller_info_image}>
							<img src={banner.seller_info_image.url} alt={banner.seller_info_image_title} 
								style={{
									width: "100%",
									height: "auto",
									objectFit: "cover",
									objectPosition: "center"
								  }}
							/>
							{RichText.render(banner.seller_info_image_title)}
							</div>
						))}
					</Box>

					<Container py="40px">
					{RichText.render(seller_info_addl)}
					</Container>

					

					<Container py="40px">
					{RichText.render(client_testimonials_header)}
					</Container>

					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							"& > div": {
							width: ["100%", "50%", "25%"],
							p: 2,
							position: "relative",
      						overflow: "hidden",
							textAlign: "center"
							}
						}}
						>
						{node.client_testimonials.map((testimonial) => (
							<div key={testimonial.client_testimonials_image}>
							<img src={testimonial.client_testimonials_image.url} alt={testimonial.client_testimonials_image_title} 
								style={{
									width: "100%",
									height: "auto",
									objectFit: "cover",
									objectPosition: "center"
								  }}
							/>
							
							{RichText.render(testimonial.client_testimonials_image_title)} 
							
							</div>
						))}
					</Box>

					<Container py="40px">
					{RichText.render(company_info)}
					</Container>

					
					<Box
						as="section"
						sx={{
							backgroundImage: `url(${call_us_banner && call_us_banner ? call_us_banner : bannerImg})`,
							...styles.banner,
						}}
						className="home-banner"
					>		
					</Box>	



				</SecondaryLayout>

			);
		}}
	/>
);

export default aboutPage;
